import {
  SpeechConfig,
  //AudioConfig,
  SpeechSynthesizer,
  SpeechSynthesisOutputFormat,
} from "microsoft-cognitiveservices-speech-sdk";

import { decode } from "base64-arraybuffer";

import queueAudio from "@/utils/queueAudio";

export default class QueueAudioManager {
  constructor() {
    //
    this.context = new (window.AudioContext || window.webkitAudioContext)();

    // init speech api
    this.speechConfig = SpeechConfig.fromSubscription(
      "639dbe741c95458480cc52066a9832e5",
      "westeurope"
    );
    this.speechConfig.speechSynthesisLanguage = "it-IT";
    this.speechConfig.speechSynthesisVoiceName = "it-IT-ElsaNeural";
    this.speechConfig.speechSynthesisOutputFormat =
      SpeechSynthesisOutputFormat.Audio24Khz48KBitRateMonoMp3;
  }

  speak(text) {
    const synthesizer = new SpeechSynthesizer(this.speechConfig, null);
    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (result) {
          synthesizer.close();

          this._decodeAudio(result.audioData);

          return result.audioData;
        }
      },
      (error) => {
        console.log(error);
        synthesizer.close();
      }
    );
  }

  beep(audio) {
    var data = decode(queueAudio.audio[audio]);
    this._decodeAudio(data);
  }

  _decodeAudio(audioData) {
    this.context.decodeAudioData(
      audioData,
      (data) => {
        // Get an AudioBufferSourceNode.
        // This is the AudioNode to use when we want to play an AudioBuffer
        var source = this.context.createBufferSource();
        // set the buffer in the AudioBufferSourceNode
        source.buffer = data;
        // connect the AudioBufferSourceNode to the
        // destination so we can hear the sound
        source.connect(this.context.destination);
        // start the source playing
        source.start();
      },
      (err) => {
        console.log("decodeAudioData ERROR");
        console.log(err);
      }
    );
  }
}
