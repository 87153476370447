<template>
  <div v-if="content && layout">
    <queue-element
      v-for="(q, index) in queues"
      v-bind:key="q.id"
      :queue="q"
      :layout="elementLayout[index]"
      :style="elementPositionsStyle[index]"
      :skeleton="layout.skeleton"
      :settings="contentSettings"
      @update-queue="updateQueues"
      @play-audio="playAudio"
    ></queue-element>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import remotecontrol from "@/utils/pusher";

import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";
import isPlainObject from "lodash/isPlainObject";
import xorBy from "lodash/xorBy";

import QueueAudioManager from "@/utils/QueueAudioManager";

export default {
  name: "QueueManager",
  mixins: [layoutMixin],
  components: {
    QueueElement: () => import("@/components/queuemanager/QueueElement"),
  },
  props: {
    content: Object,
  },
  data() {
    return {
      audioManager: new QueueAudioManager(),
      orderedQueues: [],
    };
  },
  mounted() {
    //check pusher status
    if (remotecontrol.getInstance() === null) {
      remotecontrol.init();
    }
    this.orderedQueues = this.content.contents.map((c) => c.deviceId);
  },
  watch: {
    content: function(newContent, oldContent) {
      var diff = xorBy(newContent.contents, oldContent.contents, "id");
      if (diff.length > 0) {
        this.orderedQueues = this.content.contents.map((c) => c.deviceId);
      }
    },
  },
  computed: {
    queues: function() {
      if (this.contentSettings.sortQueueByMostRecent) {
        var q = [];
        this.orderedQueues.forEach((oq) => {
          var found = this.content.contents.find((c) => c.deviceId === oq);
          if (found) {
            q.push(found);
          }
        });
        return q;
      }
      return this.content.contents;
    },
    elementLayout: function() {
      var layouts = [];
      var cols = this.content.queueColumns;
      var w = 100 / cols;
      var nQueue = 1;
      if (this.content.contents.length > 0) {
        nQueue = this.content.contents.length;
      }
      var h = 100 / Math.ceil(nQueue / cols);
      for (let i = 0; i < this.content.contents.length; i++) {
        for (let j = 0; j < cols; j++) {
          var queueLayout = cloneDeep(this.layout);

          Object.keys(queueLayout).forEach((l) => {
            if (isPlainObject(queueLayout[l])) {
              queueLayout[l].zoneWidth = (queueLayout[l].zoneWidth * w) / 100;
              queueLayout[l].zoneHeight = (queueLayout[l].zoneHeight * h) / 100;
            }
          });

          layouts.push(queueLayout);
        }
      }

      return layouts;
    },

    elementPositionsStyle: function() {
      var cols = this.content.queueColumns;
      var pos = [];
      var w = 100 / cols;
      var nQueue = 1;
      if (this.content.contents.length > 0) {
        nQueue = this.content.contents.length;
      }

      var h = 100 / Math.ceil(nQueue / cols);
      for (let i = 0; i < this.content.contents.length; i++) {
        for (let j = 0; j < cols; j++) {
          var x = j * w;
          var y = i * h;
          pos.push({
            position: "absolute",
            left: `${x}%`,
            top: `${y}%`,
            width: `${w}%`,
            height: `${h}%`,
          });
        }
      }
      return pos;
    },
    contentSettings: function() {
      return {
        queueAudioSound: this.content.queueAudioSound,
        queueAudioMode: this.content.queueAudioMode,
        queueSpeechMessage: this.content.queueSpeechMessage,
        sortQueueByMostRecent: this.content.SortQueueByMostRecent,
      };
    },
  },
  methods: {
    updateQueues: function(data) {
      var index = findIndex(this.orderedQueues, function(oq) {
        return oq == data.DeviceId;
      });

      if (index > 0) {
        var queue = this.orderedQueues.splice(index, 1);
        this.orderedQueues.unshift(queue[0]);
      }

      //play audio
      this.playAudio(data);
    },
    playAudio: function(queue) {
      switch (this.contentSettings.queueAudioMode) {
        case 1: //beep
          this.audioManager.beep(this.contentSettings.queueAudioSound);
          break;
        case 2:
          if (
            this.contentSettings.queueSpeechMessage &&
            this.contentSettings.queueSpeechMessage.length > 0
          ) {
            var qNum = parseInt(queue.Number, 10);

            if (isNaN(qNum)) {
              qNum = queue.Number;
            }

            var textToSpeech = this.contentSettings.queueSpeechMessage.replace(
              "{queue-number}",
              qNum
            );
            textToSpeech = textToSpeech.replace("{seat}", queue.Seat);
            textToSpeech = textToSpeech.replace("{seat-sign}", queue.SeatSign);
            textToSpeech = textToSpeech.replace("{service}", queue.Service);
          }

          this.audioManager.speak(textToSpeech);
          break;
      }
    },
  },
};
</script>
